import { CopyPaymentLinkDrawerSectionInformations } from "./sections/Informations";
import { CopyPaymentLinkDrawerSectionQrCode } from "./sections/QrCode";

export function CopyPaymentLinkDrawerBody() {
    return (
        <div className='history-wallet-drawer-body'>
            <CopyPaymentLinkDrawerSectionInformations />
            <CopyPaymentLinkDrawerSectionQrCode />
        </div>
    );
}
