import { useTranslation } from "react-i18next";

import { Alert } from "src/shared/atoms/Alert/Alert";

export const CopyPaymentLinkDrawerSectionInformations = () => {
    const { t } = useTranslation();
    return (
        <Alert
            message={t("CopyPaymentLinkDrawer.alert-copy-payment-link-title")}
            subMessage={t<string>("CopyPaymentLinkDrawer.alert-copy-payment-link-description")}
            icon='sparkles'
            color='lilas'
        />
    );
};
