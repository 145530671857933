import { CopyPaymentLinkDrawerBody } from "./Body";
import { DrawerContext } from "src/wrapper/context";

import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";

export function CopyPaymentLinkDrawer() {
    const { open, setOpen } = useContext(DrawerContext);
    const { t } = useTranslation();

    const handleCloseDrawer = () => {
        setOpen(undefined);
    };

    return (
        <Drawer
            isOpen={open === "copyPaymentLink"}
            onClose={handleCloseDrawer}
            header={{
                title: t("CopyPaymentLinkDrawer.title"),
                image: "get-paid",
            }}
            body={<CopyPaymentLinkDrawerBody />}
        />
    );
}
