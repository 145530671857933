import { QrCodePrintableCanva } from "../../QrCodePrintableCanva/QrCodePrintableCanva";
import { QRCodeCanvas } from "qrcode.react";
import { useReactToPrint } from "react-to-print";

import { useRef } from "react";

import { useTranslation } from "react-i18next";

import { useAuthUserSelectedCompany } from "src/modules/authCompanies/hooks";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { CopyInput } from "src/shared/components/Copy/Input";

export const CopyPaymentLinkDrawerSectionQrCode = () => {
    const { t } = useTranslation();
    const company = useAuthUserSelectedCompany();
    const contentRef = useRef<HTMLDivElement>(null);
    const paymentUrl = company ? `${window.location.origin}/payment/${company.reference ?? ""}` : "";

    const reactToPrintFn = useReactToPrint({ contentRef, documentTitle: "Julaya-payment-qr-code" });

    const handlePrint = () => {
        reactToPrintFn();
    };

    return (
        <div className='drawer-content-section'>
            <div className='drawer-content-section-header'>
                <div className='d-flex align-items-center'>
                    <Icon name='link' variant='squared' color='lilas' size='lg' />
                    <div className='d-flex flex-column ml-2'>
                        <Typography message={t("CopyPaymentLinkDrawer.section-title")} />
                    </div>
                </div>
            </div>
            <div className='drawer-content-section-body'>
                <div className='d-flex flex-column ml-2'>
                    <Typography message={t("CopyPaymentLinkDrawer.qr-code-title")} className='text-center' />
                    <Typography
                        message={t("CopyPaymentLinkDrawer.qr-code-description")}
                        className='fw-lighter text-center'
                    />
                </div>
                <div className='d-flex justify-content-center align-items-center mt-4 mb-4'>
                    <QRCodeCanvas value={paymentUrl} size={200} marginSize={2} />
                </div>
                <div className='d-flex justify-content-center align-items-center' ref={contentRef}>
                    <QrCodePrintableCanva>
                        <QRCodeCanvas value={paymentUrl} size={200} />
                    </QrCodePrintableCanva>
                </div>
                <Button
                    icon='arrow-down-on-square'
                    label={t("CopyPaymentLinkDrawer.download-qr-code")}
                    size='lg'
                    variant='tertiary'
                    className='width-100 mt-3 mb-3'
                    onClick={handlePrint}
                />
                <div className='d-flex flex-column '>
                    <Typography className='ml-2' message={t("CopyPaymentLinkDrawer.payment-link")} />
                    <div className='referal-input-container'>
                        <CopyInput
                            value={paymentUrl}
                            label=''
                            copiedContent={paymentUrl}
                            successMessage={t("CopyPaymentLinkDrawer.copy-link-msg") as string}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
