import { PropsWithChildren } from "react";

import { useTranslation } from "react-i18next";

import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

const julayaImage = require("src/shared/images/logo/julaya_pro.png");

export function QrCodePrintableCanva({ children }: { children: PropsWithChildren<JSX.Element> }) {
    const { t } = useTranslation();
    return (
        <div className='qr-code-canva'>
            <div className='logo-section'>
                <img src={julayaImage} alt='julaya logo' className='julaya-logo' />
                <Typography message={t("CopyPaymentLinkDrawer.julaya-slogan")} />
            </div>
            <div className='qr-code-title-section'>
                <Typography
                    message={t("CopyPaymentLinkDrawer.qr-code-title")}
                    className='text-center'
                    size='lg'
                    variant='h6'
                />
            </div>
            <div className='qr-code-section'>
                <div className='qr-code'>{children}</div>
            </div>
            <div className='text-center'>
                <Typography message='© Julaya SAS' variant='p' className='fw-bold' />
            </div>
        </div>
    );
}

